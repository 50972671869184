import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
    formConfig: {
      requiredNew: false,
    },
  },
  email: {
    displayName: 'emailAddress',
  },
  password: {
    displayName: 'password',
  },
  rawInfo: {
    displayName: 'rawInfo',
  },
}
const config = {
  add: function (item) {
    return hillo.post('Takeaway.php?op=addUsers', item, {
      showLoading: true,
    })
  },
  edit: function (item) {
    return hillo.postWithUploadFile('Takeaway.php?op=updateUsers', item, {
      showLoading: true,
    })
  },
  remove: function (id) {
    return hillo.post('Takeaway.php?op=deleteUsers', { id: id })
  },
  load: async function (filter) {
    return (await hillo.get('Takeaway.php?op=showAllUsers', { ...filter }))
      .content
  },
}
export default IKDataEntity.ModelFactory(entity, config)
