<template>
  <ik-data-table
    :entity-name=" $t('user_info')"
    :model="model"
    icon="mdi-card-account-details-outline"
  />
</template>

<script>

  import { IkDataTable } from 'metaflow-js'
  import Customer from '../../../model/takeaway/Customer'

  export default {
    components: {
      IkDataTable,
    },
    data: function () {
      return ({
        model: Customer,
      })
    },
  }
</script>
